<template>
  <div class="pay-success">
    <div class="pay-desc" style="font-weight: bold;">支付成功</div>
    <div class="pay-desc-sub">请及时使用，避免遗忘丢失</div>
    <div class="ecode-info-wrapper">
      <img class="ps" src="../../assets/ecodesPayed.png" />
      <div class="info-desc">点卡检测安全可用</div>
      <div class="info-desc-sub">{{ `${region} 总面值 ${Number(faceValue/100)}${currency}` }}</div>
      <div class="codes">
        <div class="code" v-for="(code, idx) in codes">{{ code }}</div>
      </div>
    </div>
    <div class="back-btn-wrapper">
      <div @click="back" class="back-btn">
        返回
      </div>
    </div>
  </div>
</template>
<script>
import { getOrderInfo } from "@/api/eshop"
import { reportError } from "@/utils/log"

export default {
  name: "PaySuccess",
  data() {
    return {
      orderId: "",
      codes: [
        'fdf'
      ],
      regionMap: {
        us: "美国",
        jp: "日本",
        au: "澳大利亚",
        hk: "香港",
        ru: "俄罗斯",
        br: "巴西",
        mx: "墨西哥",
        gb: "英国",
      },
      currency: "USD",
      currencyMap: {
        us: "美刀",
        jp: "日元",
        au: "澳元",
        hk: "港元",
        ru: "卢布",
        br: "巴西雷亚尔",
        mx: "比索",
        gb: "英镑",
      },
      faceValue: "",
      region: "美国"
    }
  },
  components: {},
  methods: {
    back() {
      this.$router.push("/home")
    },
    async getOrderInfo() {
      try {
        let res = await getOrderInfo({
          orderId: this.orderId,
        })
        if (res.data.code == 0) {
          this.codes = res.data.order.meta.codes
          this.region = this.regionMap[res.data.order.meta.currency]
          this.currency = this.currencyMap[res.data.order.meta.currency]
          this.faceValue =
            res.data.order.meta.faceValue * res.data.order.meta.buyNumber
        }
      } catch (error) {
        reportError(error)
      }
    }
  },
  created() {
    if (this.$route.query.orderId) {
      this.orderId = this.$route.query.orderId
      this.getOrderInfo()
    }
  },
}
</script>

<style lang="less" scoped>
.pay-success {
  width: 7.5rem;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: linear-gradient(180deg, #2dd39e 0%, #15c2ac 64%, #07b8b4 100%);
  .ps {
    margin-top: 0.24rem;
    width: 3.6rem;
    height: 3.6rem;
  }
  .pay-desc {
    margin-top: 0.7rem;
    font-size: 0.44rem;
    color: white;
  }
  .pay-desc-sub {
    margin-top: 0.12rem;
    font-size: 0.28rem;
    color: #ffffff;
  }
  .ecode-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.54rem;
    width: 6.36rem;
    border-radius: 44px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.08);
    img {
      width: 3.6rem;
      height: 3.6rem;
    }
    .info-desc {
      margin-top: 0.12rem;
      font-weight: bold;
      font-size: 0.36rem;
      color: #15c2ac;
    }
    .info-desc-sub {
      margin-top: 0.12rem;
      color: #737373;
      font-size: 0.32rem;
    }
    .codes {
      font-size: 0.32rem;
      font-weight: 500;
      color: #2a2d3d;
      margin-top: 0.36rem;
      margin-bottom: 0.6rem;
      .code {
        display: flex;
        align-items: center;
        width: 5.5rem;
        height: 0.7rem;
        border-radius: 0.12rem;
        background: #f8f8f8;
        justify-content: space-between;
        margin-bottom: 0.12rem;
        letter-spacing: 0.13rem;
        padding-left: 0.12rem;
        font-weight: 500;
      }
    }
  }
  .back-btn-wrapper {
    margin-top: 0.24rem;
    margin-bottom: 0.24rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .back-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.36rem;
      height: 0.8rem;
      color: #09b9b3;
      font-size: 0.28rem;
      font-weight: 500;
      background: #ffffff;
      border-radius: 0.4rem;
      box-shadow: 0px 0px 0.08rem 0.06rem rgba(0, 0, 0, 0.08);
    }
  }
  .divider {
    margin-top: 0.6rem;
    width: 100%;
    height: 0.02rem;
    background: #f6f7f8;
  }
}
</style>
